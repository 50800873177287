<template>
  <div class="home-view">
    <div class="welcome">
      <v-container class="pt-4 text-center white--text">
        <h1 class="logo-font">{{ $t("WelcomeTitle") }}</h1>
        <h2 class="text-h3 pb-6 font-weight-light">
          {{ $t("WelcomeHeader") }}
        </h2>
        <h3 class="text-h4 pb-15 font-weight-light">
          {{ $t("WelcomeDescription") }}
        </h3>

        <div class="pb-4">
          <v-btn
            to="/signup"
            color="#1d3557"
            dark
            x-large
            class="pl-15 pr-15 text-none"
            >{{ $t("SignUp") }}</v-btn
          >
        </div>
        <div class="body-2">
          {{ $t("AlreadyMember") }}&nbsp;<router-link
            class="white--text"
            to="/login"
            >{{ $t("SignIn") }}</router-link
          >
        </div>
      </v-container>
    </div>

    <div class="welcome-info pb-10">
      <v-container class="pt-15 pb-15 text-center">
        <v-row class="sub-container">
          <v-col md="6" offset-md="3" cols="12" xs="12">
            <h2 class="mt-10 font-weight-light main-h2">
              {{ $t("SubTitle") }}
            </h2>
            <p class="pt-5 subtitle-1">{{ $t("SubDescription") }}</p>
          </v-col>
        </v-row>

        <v-divider class="mt-15 mb-15"></v-divider>

        <v-row class="sub-container pt-5 pb-5">
          <v-col md="6" xs="12" cols="12">
            <v-img
              :src="pic_progress"
              class="rounded-circle mx-auto"
              :alt="$t('ChildTitle')"
              :title="$t('ChildTitle')"
              max-width="400"
              max-height="400"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col md="6" xs="12" cols="12">
            <h2 class="font-weight-light main-h2">{{ $t("ChildTitle") }}</h2>
            <p class="pt-5 subtitle-1">{{ $t("ChildDescription") }}</p>
          </v-col>
        </v-row>

        <v-divider class="mt-15 mb-15"></v-divider>

        <v-row class="sub-container pt-5 pb-5">
          <v-col md="6" xs="12" cols="12" v-if="$isMobile">
            <v-img
              :src="pic_photos"
              class="rounded-circle mx-auto"
              :alt="$t('AlbumTitle')"
              :title="$t('AlbumTitle')"
              max-width="400"
              max-height="400"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col md="6" xs="12" cols="12">
            <h2 class="font-weight-light main-h2">{{ $t("AlbumTitle") }}</h2>
            <p class="pt-5 subtitle-1">{{ $t("AlbumDescription") }}</p>
          </v-col>
          <v-col md="6" xs="12" cols="12" v-if="!$isMobile">
            <v-img
              :src="pic_photos"
              class="rounded-circle mx-auto"
              :alt="$t('AlbumTitle')"
              :title="$t('AlbumTitle')"
              max-width="400"
              max-height="400"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>

        <v-divider class="mt-15 mb-15"></v-divider>

        <v-row class="sub-container pt-5 pb-5">
          <v-col md="6" xs="12" cols="12">
            <v-img
              :src="pic_share"
              class="rounded-circle mx-auto"
              :alt="$t('ShareMemories')"
              :title="$t('ShareMemories')"
              max-width="400"
              max-height="400"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col md="6" sm="12" cols="12">
            <h2 class="font-weight-light main-h2">{{ $t("ShareMemories") }}</h2>
            <p class="pt-5 subtitle-1">{{ $t("ShareMemoriesDesc") }}</p>
            <p class="pt-5 subtitle-1 font-weight-medium">
              {{ $t("SharePrivacy") }}
            </p>
            <p class="subtitle-1 mt-1">{{ $t("SharePrivacyDescription") }}</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<i18n>
    {
    "en": {
      "WelcomeTitle": "Babiry",
      "WelcomeHeader": "Your Family Diary Online",
      "WelcomeDescription": "Capture memories of your family and share with family members and friends",
      "SignUp": "Sign Up for Free",
      "AlreadyMember": "Already a member?",
      "SignIn": "Login",
      "SubTitle": "Babiry is your family diary",
      "SubDescription": "From birth through to the present day. On Babiry you can collect all the photos and memories of your children so you can remember them forever.",

      "ChildTitle": "The child's progress and development",
      "ChildDescription": "Upload pictures, add progress and record the child's height and weight to get a growth curve.",

      "AlbumTitle": "Organize your photos",
      "AlbumDescription": "How many times have you heard “Quick! Get the camera!” in the first couple of years of your child’s life? Every year, people take thousands of photos of their children. With today’s technology, cameras are easily accessible on our phones and tablets, and everyone in your family is going to take their own photos.",

      "ShareMemories": "Share memories",
      "ShareMemoriesDesc": "Share memories with family members and friends. The first steps, the first words, favorite food and much more.",
      "SharePrivacy": "Privacy",
      "SharePrivacyDescription": "Your privacy is really important for us. You can att any time change your privacy for your diary who can visit and read it."
    },
    "sv": {
      "WelcomeTitle": "Barndagboken",
      "WelcomeHeader": "Din Familjedagbok Online",
      "WelcomeDescription": "Spara minnen från din familj och dela med familjemedlemmar och vänner",
      "SignUp": "Bli medlem Gratis",
      "AlreadyMember": "Redan medlem?",
      "SignIn": "Logga in",

      "SubTitle": "Barndagboken är familjens dagbok",
      "SubDescription": "Från födseln till idag. På Barndagboken kan du samla alla foton och minnen från dina barn så att du kommer ihåg dem för alltid och dela med dig till vänner och bekanta.",

      "ChildTitle": "Barnets framsteg och utveckling",
      "ChildDescription": "Ladda upp bilder, lägg till framsteg och registrera barnets längd och vikt för att få fram en tillväxtkurva.",

      "AlbumTitle": "Organisera dina bilder",
      "AlbumDescription": "Hur många gånger har du inte hört “Snabbt, fram med kameran!” under de första åren av ditt barns liv? Varje år tar man mängder med bilder på sina barn. Med dagens teknik är kameror lättillgängliga på våra telefoner och surfplattor, och alla i din familj kommer att ta sina egna foton.",

      "ShareMemories": "Dela med dig av minnen",
      "ShareMemoriesDesc": "Dela med dig av minnen till familjemedlemmar och vänner.",
      "SharePrivacy": "Privacy",
      "SharePrivacyDescription": "Din integritet är verkligen viktig för oss. Du kan när som helst ändra vem som kan besöka och läsa din dagbok."
    }
    }
</i18n>

<script>
export default {
  name: "home",
  metaInfo() {
    return {
      titleTemplate: "",
      title: this.$t("MainPage.Title"),
      meta: [
        {
          vmid: "pic_welcome",
          rel: "preload",
          href: this.pic_welcome,
          as: "image",
        },
        {
          vmid: "pic_photos",
          rel: "preload",
          href: this.pic_photos,
          as: "image",
        },
        {
          vmid: "pic_share",
          rel: "preload",
          href: this.pic_share,
          as: "image",
        },
        {
          vmid: "pic_progress",
          rel: "preload",
          href: this.pic_progress,
          as: "image",
        },
      ],
    };
  },
  data: () => ({
    pic_progress: "https://cdn.babiry.com/web/home/child_400.jpg",
    pic_photos: "https://cdn.babiry.com/web/home/album_400.jpg",
    pic_share: "https://cdn.babiry.com/web/home/share_400.jpg",
    pic_welcome: "https://cdn.babiry.com/web/home/welcome.jpg",
  }),
  created() {
    if (window.sessionStorage && window.sessionStorage.getItem("webpSupport")) {
      this.pic_progress = "https://cdn.babiry.com/web/home/child_400.webp";
      this.pic_photos = "https://cdn.babiry.com/web/home/album_400.webp";
      this.pic_share = "https://cdn.babiry.com/web/home/share_400.webp";
      this.pic_welcome = "https://cdn.babiry.com/web/home/welcome.webp";
    }
  },
};
</script>

<style scope>
html:not(.webp) .welcome {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://cdn.babiry.com/web/home/welcome.jpg") no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  min-height: 800px;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-size: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
html.webp .welcome {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://cdn.babiry.com/web/home/welcome.webp") no-repeat center top;
  background-attachment: relative;
  background-position: center center;
  min-height: 800px;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  background-size: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome h1 {
  font-size: 104px !important;
  font-weight: 300;
  line-height: 96px;
  letter-spacing: -0.015625em !important;
  padding-bottom: 48px;
}
.welcome h2.text-h3 {
  font-size: 48px !important;
}
.welcome h3.text-h4 {
  font-size: 34px !important;
}
.sub-container .subtitle-1 {
  font-size: 17.6px !important;
}

@media screen and (max-width: 768px) {
  .welcome {
    min-height: 500px !important;
    padding-bottom: 15px;
  }
  .welcome h1 {
    font-size: 45px !important;
    line-height: 49px !important;
    word-break: break-all !important;
    padding-bottom: 25px !important;
    padding-top: 20px;
  }
  .welcome h2.text-h3 {
    font-size: 30px !important;
  }
  .welcome h3.text-h4 {
    font-size: 20px !important;
  }

  h2.main-h2 {
    font-size: 30px !important;
  }
}
</style>